import React, { Component } from 'react'

export default class TimeLine extends Component {
    render() {
        return (
            <div>
                <h1>Timeline</h1>
                <div className="item">
                    <div id="timeline">
                        <div>
                            <section class="year">
                                <h3 className="initial">2020</h3>
                                <section>
                                    <ul>
                                        <li>Working at SwarmBox.</li>
                                        <li>Started developing a VR Boxing Game using Unity.</li>
                                    </ul>
                                </section>
                            </section>
                            <section className="year">
                                <h3>2019</h3>
                                <section>
                                    <ul>
                                        <li>Continued working at Swarmbox.</li>
                                        <li>Started developing a recipe generator web app with web scraping and machine learning.</li>
                                    </ul>
                                </section>
                            </section>
                            <section class="year">
                                <h3>2018</h3>
                                <section>
                                    <h4>April</h4>
                                    <ul>
                                        <li>Started working at SwarmBox.</li>
                                    </ul>
                                </section>
                                <section>
                                    <h4>Until November</h4>
                                    <ul>
                                        <li>Continued working at Visual Impact Techonologies Inc.</li>
                                    </ul>
                                </section>
                                <section>
                                    <h4>January - October</h4>
                                    <ul>
                                        <li>Worked as a freelancer on Upwork for a real estate listing and management website.</li>
                                    </ul>
                                </section>
                                <section>
                                    <ul>
                                        <li>Redesigned Textmate using GoLang and Google NLP.</li>
                                    </ul>
                                </section>
                            </section>
                            <section className="year">
                                <h3>2017</h3>
                                <section>
                                    <h4>September</h4>
                                    <ul>
                                        <li>Began working at Visual Impact Technologies Inc. on projects focused on agricultural products and management.</li>
                                    </ul>
                                </section>
                                <section>
                                    <h4>May</h4>
                                    <ul>
                                        <li>Graduated from CSU Fresno, with a Bachelor's Degree in Computer Science</li>
                                    </ul>
                                </section>
                                <section>
                                    <ul>
                                        <li>Developed DealBreaker Dating from an Android App into a website.</li>
                                    </ul>
                                </section>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}