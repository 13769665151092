import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div>
            <h1>About Me</h1>
            <div className="container-fluid">
                <section className="about">
                <p>
                    My name is Joseph Vietti, I am a Software Engineer from Fresno, California, that is interested in Web and Mobile Development, Game Development, and Computer Architecture.
                    I graduated with a Bachelor's Degree in Computer Science at Fresno State in May of 2017. 
                    I have interests in sports such as cross country, martial arts, and swimming; some of which I have competed in throughout my life.
                </p>
                <div className="row">
                    <div className="col">
                        <div className="body">
                            <h4 className="heading">Looking to Make a Difference</h4>
                            <p>
                                Ultimately my goal is to leave an impactful difference large or small, on both
                                my peers and any users of the software I develop. By impactful difference I don't mean a life altering experience,
                                rather any sort of measurable impact from a change in mood to an improvement in thinking or understanding.
                                
                            </p>
                            <h4 className="heading">Interests</h4>
                            <p>
                                I particularly enjoy software development from web development to game development, 
                                as well as more specific topics such as machine learning, graphic rendering pipelines,
                                and parallel programming. 
                            </p>
                            <p> In my spare time I enjoy playing video and table top games, running, reading/discussing philosophy, and more. </p>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            </div>
        )
    }
}