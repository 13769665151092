import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
            <div>
                <h1>Contact Me</h1>
                <div className="container-fluid">
                    <section className="about">
                        <p>Contact me for business or other inqueries.</p>
                        <p><i className="fa fa-envelope mr-2"></i><a href="mailto:joedvietti@gmail.com">joedvietti@gmail.com</a></p>
                    </section>
                </div>
            </div>
        )
    }
}