import React from "react";
import "./App.scss";
import Sidebar from "./components/sidebar";
import About from "./components/about";
import TimeLine from "./components/timeline";
import Projects from "./components/projects";
import Contact from "./components/contact";

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row d-flex flex-nowrap">
          <div className="pl-0 collapse show width" id="sidebar">
            <Sidebar></Sidebar>
          </div>
          <main className="main col">
            <About></About>
            <TimeLine></TimeLine>
            <Projects></Projects>
            <Contact></Contact>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
