import React, { Component } from 'react'

export default class Projects extends Component {
    render() {
        return (
            <div className="projects">
                <h1>Projects</h1>
                <div className="container-fluid">
                    <section className="projects">
                    <div className="row">
                        <h5 className="heading col-12 col-md-3">DealBreaker Dating</h5>
                        <div className="col">
                            <p>
                             <strong>Website</strong> <a href="https://github.com/JEVietti/DealBreaker-WebJS"><small>Repository</small></a>
                            </p>
                            <p>
                                A dating <a href="https://dealbreakerdating.herokuapp.com/" alt="">website</a> in which users find others meeting their preferences and location, while avoiding those with qualities that are deal breakers.
                                Implemented using Angular 2+ with a NodeJS backend, currently up for demoing purposes via Heroku.
                            </p>
                            <p>
                                <strong>Android App</strong> <a href="https://github.com/JEVietti/DealBreaker"><small>Repository</small></a>
                            </p>
                            <p>
                                An application that pairs users by meeting their preferences and location, while avoiding those with qualities that the user sets as deal breakers.
                                 The app was developed as a team project during a Software Engineering Course at Fresno State, where I was the Project Leader.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <h5 className="heading col-12 col-md-3">TextMate</h5>
                        <div className="col">
                            <p><strong>Android App</strong> <a href="https://github.com/JEVietti/TextMateAPI"><small>API</small></a> <a href="https://github.com/JEVietti/TextMateV2"><small>App</small></a></p>
                            <p>Originally developed as a group project using SQLite and Firebase during a Computer Science course in college.
                                TextMate is an app with the goal of providing a relationship score and metrics via a user's text message history.
                            </p>
                            <p>In 2018, I decided to rewrite the project on my own using GoLang as the REST API, and interface with Android App via Retrofit and GSON.
                                In addition to my own algoritm to evaluate the realtionship data based on text message history via frequence, content length and more.
                                I utilized Google's Natural Language Processing to evaluate the sentiment of a given message applied to the factors above, to generate an accurate relationship appraisal, along with other metrics.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <h5 className="heading col-12 col-md-3">Boxing VR Game</h5>
                        <div className="col">
                            <p><strong>Unity Game <small>Under Development</small></strong></p>
                            <p>Currently under development, the goal of this game is to provide the user with a simulation like boxing experience.
                                Allowing the user to train, spar, and compete against online opponents from the comfort of their own home, with no risk of harm to themselves or others.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <h5 className="heading col-12 col-md-3">Recipe Generator</h5>
                        <div className="col">
                            <p><strong>Website <small>Under Development</small></strong></p>
                            <p>A website that will use a combination of web scraping and ratings to provide
                                users accurate recipe suggestions based on lifestyle and ingredients entered.
                                I plan to utilize machine learning principles along with GraphQL to make reasonable
                                suggestions based on user patterns, and it will be powered using GoLang, React, and PostgreSQL.
                            </p>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
        )
    }
}