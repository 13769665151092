import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logoBordered from '../logo-bordered.png';

export default class Sidebar extends Component {
    render() {
        return (
            <aside>
                <div className="logoContainer">
                    <a href="/"><img className="logo" src={logoBordered} alt="Profile" /></a>  
                </div>
                <nav className="nav">
                    <ul>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        <li>
                            <a href="/timeline">Timeline</a>
                        </li>
                        <li>
                            <a href="/projects">Projects</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <div className="socials">
                        <a className="mr-2" href="https://github.com/JEVietti"><img className="github-sidebar-logo" src="img/GitHub-Mark-64px.png" alt="GitHub"/></a>
                        <a href="https://linkedin.com/JEVietti"><img className="linkedin-sidebar-logo" src="img\LI-In-Bug.png" alt=""/></a>
                    </div>
                </div>
            </aside>
        )
    }
}